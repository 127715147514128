/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { User, getAuth } from "firebase/auth"

export function setupAxios(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(async (config) => {
    const auth = getAuth()
    const user: User | null = auth.currentUser

    if (user) {
      const token = await user.getIdToken()
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  })

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      // Handle errors, for instance, redirect to login on 401 status
      if (error.response?.status === 401) {
        // Redirect to login or handle accordingly
      }

      return Promise.reject(error)
    }
  )
}

export { getAuth }
