import { createRoot } from "react-dom/client";
import "./style.css";
import { AuthProvider } from "./auth/core/Auth";
import { AppRoutes } from "./routes/AppRoutes";
import { setupAxios } from "./auth/core/AuthHelpers";
import axios from "axios";

const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);
setupAxios(axios);

root.render(
  <AuthProvider>
    <AppRoutes />
  </AuthProvider>
);

// Check that service workers are supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(reg => console.log('Service Worker registered', reg))
      .catch(err => console.log('Service Worker registration failed', err));
  });
}
