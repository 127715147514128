import { useGLTF } from "@lib/useGLTF"
import React, { useEffect, useMemo } from "react"
import { useStore } from "@state/store"
import MeshElement from "@canvas-components/mesh-element"
import { useLocation } from "react-router-dom"
import { ThreeEvent } from "@react-three/fiber"
import { floorMeshData } from "@data/floor-mesh-data"

const BuidlingFloors = ({ env }) => {
  const groupedByLastString = useMemo(() => {
    return floorMeshData.reduce((acc, item) => {
      const key = item.split("-")[1]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(item)
      return acc
    }, {})
  }, [])

  return (
    <group>
      {Object.values(groupedByLastString).map((objects, i) => (
        <FloorElement
          key={Object.keys(groupedByLastString)[i]}
          floorNumber={Object.keys(groupedByLastString)[i]}
          objects={objects}
          env={env}
        />
      ))}
    </group>
  )
}

const FloorElement = ({ objects, env, floorNumber }) => {
  const selectedFloors = useStore((s) => s.selectedFloors)
  const setSelectedFloors = useStore((s) => s.setSelectedFloors)
  const hovered = useStore((s) => s.hovered)
  const setHovered = useStore((s) => s.setHovered)
  // const [hovered, setHovered] = React.useState<boolean | null>(null)

  const pathname = useLocation().pathname

  useEffect(() => {
    if (!pathname.includes("workspaces")) {
      setSelectedFloors([])
    }
  }, [pathname])

  return (
    <group>
      <BoundingBoxElement
        selectedFloors={selectedFloors}
        floorNumber={Number(floorNumber)}
        setHovered={pathname.includes("workspaces") ? setHovered : () => null}
        setSelectedFloors={setSelectedFloors}
      />

      {objects.map((name) => (
        <BuildingElement
          selectedFloors={selectedFloors}
          hovered={hovered}
          env={env}
          key={name}
          name={name}
        />
      ))}
    </group>
  )
}

const BuildingElement = ({ name, env, hovered, selectedFloors }) => {
  const floor = Number(name.split("-")[1])
  const location = useLocation()
  const route = location.pathname.split("/")[1]

  const filteredLayer = useStore((s) => s.filteredLayer)

  const setSelectedFloor = useStore((s) => s.setSelectedFloor)
  const setHoverClick = useStore((s) => s.setHoverClick)

  useEffect(() => {
    setSelectedFloor(-1)
    if (route === "workspaces") setHoverClick(true)
  }, [route, filteredLayer])

  let hr: any[] | number | null

  if (Array.isArray(hovered)) {
    hr = hovered
  } else {
    hr = [hovered]
  }

  return (
    <group>
      <MeshElement
        env={env}
        name={name}
        hovered={hr?.includes(floor)}
        highlighted={selectedFloors.includes(floor)}
      />
    </group>
  )
}

const BoundingBoxElement = ({
  floorNumber,
  setHovered,
  setSelectedFloors,
  selectedFloors,
}) => {
  const { nodes } = useGLTF(`/models/floors/bounding-box/fl_${floorNumber}.glb`)

  const pathname = useLocation().pathname

  const array = Object.values(nodes) as THREE.Mesh[]

  const onSelectFloor = (e: ThreeEvent<any>, floor: number) => {
    if (!pathname.includes("workspaces")) return
    e.stopPropagation()

    setSelectedFloors(
      schoolFloors.includes(floorNumber)
        ? schoolFloors
        : selectedFloors.includes(floorNumber)
        ? selectedFloors.filter((e) => e !== floorNumber)
        : selectedFloors.includes(3)
        ? [floorNumber]
        : [...selectedFloors, floorNumber]
    )
  }

  return (
    <group>
      {array.map((mesh) => (
        <mesh
          key={floorNumber}
          onClick={(e) => {
            if (floorNumber > 26) return
            onSelectFloor(e, floorNumber)
          }}
          visible={true}
          onPointerOver={(e) => {
            e.stopPropagation()

            if (floorNumber > 26) return

            setHovered(
              schoolFloors.includes(floorNumber) ? schoolFloors : floorNumber
            )
          }}
          onPointerLeave={(e) => {
            e.stopPropagation()
            setHovered(null)
          }}
          geometry={mesh.geometry}
          material={mesh.material}
        >
          <meshBasicMaterial color="white" visible={false} />
        </mesh>
      ))}
    </group>
  )
}

export default BuidlingFloors

const schoolFloors = [2, 3, 4, 5]
