import { useLocation } from "react-router-dom"

interface PositionSettings {
  meshScaleGsap: {
    x: number
    y: number
    z: number
    duration: number
    ease: string
  }
  meshPositionGsap: {
    x?: number
    y: number
    z: number
    duration: number
    ease: string
  }
  cameraPosition: {
    x: number
    y: number
    z: number
    duration: number
    ease: string
  }
}

const usePositionSettings = (): PositionSettings => {
  const location = useLocation()

  const positions: Record<string, PositionSettings> = {
    "/onegrand/building": {
      meshScaleGsap: {
        x: 2,
        y: 2,
        z: 2,
        duration: 1.5,
        ease: "power2.inOut",
      },
      meshPositionGsap: {
        x: 0,
        y: 0.3,
        z: 0,
        duration: 1.5,
        ease: "power2.inOut",
      },
      cameraPosition: {
        x: 0,
        y: 0,
        z: 7,
        duration: 1.5,
        ease: "power2.inOut",
      },
    },
    "/onegrand/public": {
      meshScaleGsap: {
        x: 3.5,
        y: 3.5,
        z: 3.5,
        duration: 1.5,
        ease: "power2.inOut",
      },
      meshPositionGsap: { y: 2.6, z: 0, duration: 1.5, ease: "power2.inOut" },
      cameraPosition: {
        x: 0,
        y: 0.7,
        z: 7,
        duration: 1.5,
        ease: "power2.inOut",
      },
    },
    "/onegrand/amenities": {
      meshScaleGsap: { x: 8, y: 8, z: 8, duration: 1.5, ease: "power2.inOut" },
      meshPositionGsap: { y: -8, z: 1, duration: 1.5, ease: "power2.inOut" },
      cameraPosition: {
        x: 5.8,
        y: 1.9,
        z: 2.7,
        duration: 1.5,
        ease: "power2.inOut",
      },
    },
    "/gallery": {
      meshScaleGsap: {
        x: 2,
        y: 2,
        z: 2,
        duration: 1.5,
        ease: "power2.inOut",
      },
      meshPositionGsap: {
        x: -3,
        y: 0.3,
        z: 0,
        duration: 1.5,
        ease: "power2.inOut",
      },
      cameraPosition: {
        x: -3,
        y: 0,
        z: 9,
        duration: 1.5,
        ease: "power2.inOut",
      },
    },
  }

  return positions[location.pathname] || positions["/onegrand/building"] // Default to building position
}

export default usePositionSettings
