#include <output_fragment>

vec3 shaderOutput = vec3(outgoingLight.r, outgoingLight.g, outgoingLight.b);

vec3 activeCol = vec3(0.75, 0.75, 0.75);
// vec3 highlightedCol = vec3(0.29, 0.59, 0.87);
// vec3 highlightedCol = vec3(0.96, 0.42, 0.07);
vec3 highlightedCol = vec3(0.00, 0.40, 1.00);
vec3 selectedCol = vec3(1.0, .0, .0);
vec3 off = vec3(0.0, 0.0, 0.0);

vec3 finalColor = mix(shaderOutput, selectedCol, uSelected);
finalColor = mix(finalColor, highlightedCol, uHighlighted);
finalColor = mix(finalColor, activeCol, uActive);

// gl_FragColor = vec4(shaderOutput.rgb, opacity);
gl_FragColor = vec4(finalColor.rgb, opacity);
// gl_FragColor = vec4(1., 0.0, 0.1, 1.0);
