import { useStore } from "@state/store"
import styled from "styled-components"
import SVG from "react-inlinesvg"
import { NavLink } from "react-router-dom"

type WorkspacesSubMenuProps = {
  activeSubMenu: any
}

export const UnitViewSubMenu: React.FC<WorkspacesSubMenuProps> = ({
  activeSubMenu,
}) => {
  const setFilteredLayer = useStore((s) => s.setFilteredLayer)
  const filteredLayer = useStore((s) => s.filteredLayer)
  const currentSubMenuIndex = useStore((s) => s.currentSubMenuIndex)
  const setCurrentSubMenu = useStore((s) => s.setCurrentSubMenu)

  const resetSubMenuIndex = (index: number, isUnitView: boolean) => {
    if (isUnitView) {
      setCurrentSubMenu(index)
    } else {
      setCurrentSubMenu(index)

      if (filteredLayer.includes(index) && !isUnitView) {
        setFilteredLayer(filteredLayer.filter((e) => e != index))
      } else {
        setFilteredLayer([...filteredLayer, index])
      }
    }
  }

  return (
    <>
      {activeSubMenu.map(({ label, sublabel }: any, index: number) => {
        return (
          <NavLink
            key={label}
            to="#"
            className={`submenu-item-unitview ${
              currentSubMenuIndex === index ? "selected" : ""
            }`}
            onClick={() => {
              resetSubMenuIndex(index, true)
            }}
          >
            <div className="circle-icon">
              <StyledSvg
                data-isactive={currentSubMenuIndex === index}
                src={`/icons/${label.toLowerCase()}.svg`}
                title="Icon"
              />
            </div>
            {label}
          </NavLink>
        )
      })}
    </>
  )
}

//@ts-ignore
const StyledSvg = styled(SVG)`
  & path {
    stroke: #393d3d;
    transition: all 0.5s ease;
  }

  &[data-isactive="false"] {
    & path {
      stroke: #d7c0b0;
      transition: all 0.5s ease;
    }
  }
`
