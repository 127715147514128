// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSnDquR7VIM9a96au3e1RWNCvGQAzRuBU",
  authDomain: "luna-auth-eed78.firebaseapp.com",
  projectId: "luna-auth-eed78",
  storageBucket: "luna-auth-eed78.appspot.com",
  messagingSenderId: "42982071646",
  appId: "1:42982071646:web:5490d85a8b99f9d1f783cf",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
