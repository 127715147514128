import { useStore } from "@state/store"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"

type OneGrandSubMenuProps = {
  activeSubMenu: any
}

const OneGrandSubMenu: React.FC<OneGrandSubMenuProps> = ({ activeSubMenu }) => {
  // const [scrollSelected, setScrollSelected] = useState(false)
  const { setCurrentSubMenu } = useStore((s) => ({
    setCurrentSubMenu: s.setCurrentSubMenu,
  }))

  const buildingRef = useRef<HTMLAnchorElement>()
  const greenRef = useRef<HTMLAnchorElement>()
  const amenitiesRef = useRef<HTMLAnchorElement>()
  const publicRef = useRef<HTMLAnchorElement>()

  let refArray = [buildingRef, greenRef, amenitiesRef, publicRef]

  const resetSubMenuIndex = useCallback((index: number) => {
    setCurrentSubMenu(index)
    // if (!scrollSelected) {
    // }
  }, [])

  return (
    <>
      {activeSubMenu?.map(({ to, label }, index: number) => (
        <NavLink
          ref={refArray[index]}
          key={to + label}
          to={to}
          // onPointerOver={() => {
          //   setScrollSelected(false)
          // }}
          onClick={() => resetSubMenuIndex(index)}
        >
          {label}
        </NavLink>
      ))}
    </>
  )
}

export default OneGrandSubMenu
