import { toAbsoluteUrl } from "@utils/toAbsoluteUrl"
import { FC } from "react"
import { Link } from "react-router-dom"

const Error500: FC = () => {
  return (
    <>
      <h1>System Error</h1>
      <div>Something went wrong! Please try again later.</div>

      <div>
        <img src={toAbsoluteUrl("/media/auth/500-error.png")} alt="" />
        <img src={toAbsoluteUrl("/media/auth/500-error-dark.png")} alt="" />
      </div>
      <div>
        <Link to="/dashboard">Return Home</Link>
      </div>
    </>
  )
}

export { Error500 }
