import { create } from "zustand"

interface InitialState {
  cameraPosition: any
  setCameraPosition: (v: any) => void
  cameraSettings: any
  setCameraSettings: (v: any) => void
  activeMesh: string[] | null
  setActiveMesh: (v: string) => void

  controlsCatcher: any
  setcontrolsCatcher: (v: any) => void

  animationComplete: boolean
  setAnimationComplete: (v: boolean) => void

  subMenuVisible: boolean
  setSubMenuVisible: (v: boolean) => void

  selectedFloor: number
  setSelectedFloor: (v: number) => void

  filteredLayer: Array<number>
  setFilteredLayer: (v: Array<number>) => void

  currentSubMenuIndex: number
  setCurrentSubMenu: (v: number) => void

  currentMainMenu: number
  setCurrentMainMenu: (v: number) => void

  isCanvasLoaded: boolean
  setCanvasLoad: (v: boolean) => void

  enableOrbitScrollSelection: boolean
  setOrbitScroll: (v: boolean) => void

  enableHoverClick: boolean
  setHoverClick: (v: boolean) => void

  currentScroll: number
  setCurrentScroll: (v: number) => void

  startDraging: boolean
  setStartDraging: (v: boolean) => void

  selectionVisible: boolean
  setSelectionVisible: (v: boolean) => void

  selectionStart: any
  setSelectionStart: (v: any) => void

  selectionEnd: any
  setSelectionEnd: (v: any) => void

  unitview: boolean
  setUnitView: (v: boolean) => void

  cameraMove: boolean
  setCameraMove: (v: boolean) => void

  selectedFloors: number[]
  setSelectedFloors: (v: number[]) => void
  addSelectedFloor: (v: number) => void
  mapAccordionValue: string
  setMapAccordionValue: (v: string) => void

  currAccordionItemIndex: number
  setCurrAccordionItemIndex: (v: number) => void

  initialSwiperGalleryIndex: number
  setInitialSwiperGalleryIndex: (v: number) => void

  hovered: number
  setHovered: (v: number) => void

  isIpad: boolean
}

export const useStore = create<InitialState>()((set, get) => ({
  hovered: null,
  setHovered: (v) => set({ hovered: v }),
  selectedFloors: [],
  setSelectedFloors: (v) => set({ selectedFloors: v }),
  addSelectedFloor: (v) =>
    set({ selectedFloors: [...get().selectedFloors, v] }),

  cameraPosition: { x: 0, y: 0, z: 0 },
  setCameraPosition: (v) => set({ cameraPosition: v }),
  animationComplete: false,
  setAnimationComplete: (v) => set({ animationComplete: v }),
  subMenuVisible: false,
  setSubMenuVisible: (v) => set({ subMenuVisible: v }),
  cameraSettings: {
    position: { x: 0, y: 0, z: 0 },
    target: { x: 0, y: 0, z: 0 },
    duration: 1000,
    minDistance: 100,
  },
  setCameraSettings: (v) => set({ cameraSettings: v }),
  controlsCatcher: null,
  setcontrolsCatcher: (v) => set({ controlsCatcher: v }),

  activeMesh: ["building"],
  setActiveMesh: (v: string) => {
    if (v === "clear") {
      set({ activeMesh: [] })
      return
    }

    if (get().activeMesh.includes(v)) {
      set({ activeMesh: get().activeMesh.filter((item) => item !== v) })
    } else {
      set({ activeMesh: [...get().activeMesh, v] })
    }
  },

  selectedFloor: -1,
  setSelectedFloor: (v) => set({ selectedFloor: v }),

  filteredLayer: [],
  setFilteredLayer: (v) => set({ filteredLayer: v }),

  currentSubMenuIndex: null,
  setCurrentSubMenu: (v) => set({ currentSubMenuIndex: v }),

  currentMainMenu: 0,
  setCurrentMainMenu: (v) => set({ currentMainMenu: v }),

  isCanvasLoaded: false,
  setCanvasLoad: (v) => set({ isCanvasLoaded: v }),

  enableOrbitScrollSelection: true,
  setOrbitScroll: (v) => set({ enableOrbitScrollSelection: v }),

  enableHoverClick: false,
  setHoverClick: (v) => set({ enableHoverClick: v }),

  currentScroll: 0,
  setCurrentScroll: (v) => set({ currentScroll: v }),

  startDraging: false,
  setStartDraging: (v) => set({ startDraging: v }),

  selectionVisible: false,
  setSelectionVisible: (v) => set({ selectionVisible: v }),

  selectionStart: { x: 0, y: 0 },
  setSelectionStart: (v) => set({ selectionStart: v }),

  selectionEnd: { x: 0, y: 0 },
  setSelectionEnd: (v) => set({ selectionEnd: v }),

  unitview: false,
  setUnitView: (v) => set({ unitview: v }),

  cameraMove: false,
  setCameraMove: (v) => set({ cameraMove: v }),

  mapAccordionValue: null,
  setMapAccordionValue: (v) => set({ mapAccordionValue: v }),

  currAccordionItemIndex: null,
  setCurrAccordionItemIndex: (v) => set({ currAccordionItemIndex: v }),

  initialSwiperGalleryIndex: 0,
  setInitialSwiperGalleryIndex: (v) => set({ initialSwiperGalleryIndex: v }),

  // isIpad: !!(window as any).__TAURI_INTERNALS__,  // Instead of tauri it should be if isIpad
  isIpad: navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 2,
}))
