export const floorMeshData = [
  "basep_0-1",
  "basep_Fill_Windows_0-1",
  "Doors_0-1",
  "Garage_0-1",
  "Glass_0-1",
  "Window_frames_0-1",
  "basep_Raling_10-11",
  "basep_Sweep_10-11",
  "Doors_10-11",
  "Glass_10-11",
  "Pillars_10-11",
  "Slabs_10-11",
  "Windows_Frame_10-11",
  "Balcony_11-12",
  "Door_11-12",
  "Glass_11-12",
  "Slabs_11-12",
  "Windows_Frame_11-12",
  "Glass_12-13",
  "Pillars_12-13",
  "Slabs_12-13",
  "Windows_Frame_12-13",
  "Balcony_13-14",
  "Door_13-14",
  "Glass_13-14",
  "Pillars_13-14",
  "Slabs_13-14",
  "Windows_Frame_13-14",
  "Glass_14-15",
  "Pillars_14-15",
  "Slabs_14-15",
  "Windows_Frame_14-15",
  "Balcony_15-16",
  "Door_15-16",
  "Glass_15-16",
  "Pillars_15-16",
  "Slabs_15-16",
  "Windows_Frame_15-16",
  "Glass_16-17",
  "Pillars_16-17",
  "Slabs_16-17",
  "Windows_Frame_16-17",
  "Balcony_17-18",
  "Door_17-18",
  "Glass_17-18",
  "Pillars_17-18",
  "Slabs_17-18",
  "Windows_Frame_17-18",
  "Glass_18-19",
  "Pillars_18-19",
  "Slabs_18-19",
  "Windows_Frame_18-19",
  "Balcony_19-20",
  "Door_19-20",
  "Glass_19-20",
  "Pillars_19-20",
  "Slabs_19-20",
  "Windows_frame_19-20",
  "basep_Railing_01-2",
  "basep_Sweep_01-2",
  "Glass_01-2",
  "Shutters_01-2",
  "Slabs_01-2",
  "Windows_Frame_01-2",
  "Glass_20-21",
  "Pillars_20-21",
  "Slabs_20-21",
  "Windows_Frame_20-21",
  "Balcony_21-22",
  "Door_21-22",
  "Fascade_Sweep_000-22",
  "Fascade_Sweep_001-22",
  "Fascade_Sweep_002-22",
  "Fascade_Sweep_003-22",
  "Fascade_Sweep_004-22",
  "Fascade_Sweep_005-22",
  "Fascade_Sweep_006-22",
  "Fascade_Sweep_007-22",
  "Fascade_Sweep_008-22",
  "Fascade_Sweep_009-22",
  "Fascade_Sweep_010-22",
  "Fascade_Sweep_011-22",
  "Fascade_Sweep_012-22",
  "Fascade_Sweep_013-22",
  "Fascade_Sweep_014-22",
  "Fascade_Sweep_015-22",
  "Fascade_Sweep_016-22",
  "Fascade_Sweep_017-22",
  "Glass_21-22",
  "Pillars_21-22",
  "Slabs_21-22",
  "Windows_Frame_21-22",
  "Glass_22-23",
  "Pillars_22-23",
  "Slabs_22-23",
  "Windows_Frame_22-23",
  "Balcony_23-24",
  "Door_23-24",
  "Glass_23-24",
  "Pillars_23-24",
  "Slabs_23-24",
  "Windows_Frame_23-24",
  "Glass_24-25",
  "Pillars_24-25",
  "Slabs_24-25",
  "Windows_Frame_24-25",
  "Balcony_25-26",
  "Door_25-26",
  "Glass_25-26",
  "Pillars_25-26",
  "Slabs_25-26",
  "Windows_Frame_25-26",
  "basep_Sweep_26-27",
  "Glass_26-27",
  "Pillars_26-27",
  "Slabs_26-27",
  "Windows_Frame_26-27",
  "basep_Sweep_27-28",
  "Door_27-28",
  "Glass_27-28",
  "Green_27-28",
  "Pillars_27-28",
  "Roof_Slabs_27-28",
  "Windows_Frame_27-28",
  "basep_Railing_02-3",
  "basep_Sweep_02-3",
  "Glass_02-3",
  "Slabs_02-3",
  "Windows_Frame_02-3",
  "basep_Railing_03-4",
  "basep_Sweep_03-4",
  "Glass_03-4",
  "Slabs_03-4",
  "Windows_Frame_03-4",
  "basep_04-5",
  "basep_Railing_04-5",
  "basep_Sweep_04-5",
  "Doors_04-5",
  "Glass_04-5",
  "Pillars_04-5",
  "Slabs_04-5",
  "Windows_Frame_04-5",
  "Balcony_05-6",
  "basep_Raling_05-6",
  "basep_Sweep_05-6",
  "Glass_05-6",
  "Pillars_05-6",
  "Shutters_05-6",
  "Slabs_05-6",
  "Windows_Frame_05-6",
  "basep_Raling_06-7",
  "basep_Sweep_06-7",
  "Glass_06-7",
  "Pillars_06-7",
  "Slabs_06-7",
  "Windows_Frame_06-7",
  "basep_Railing_07-8",
  "basep_Sweep_07-8",
  "Glass_07-8",
  "Pillars_07-8",
  "Slabs_07-8",
  "Windows_Frame_07-8",
  "basep_Railing_08-9",
  "basep_Sweep_08-9",
  "Door_08-9",
  "Glass_08-9",
  "Pillars_08-9",
  "Slabs_08-9",
  "Windows_Frame_08-9",
  "Balcony_09-10",
  "basep_000-10",
  "basep_001-10",
  "basep_002-10",
  "basep_003-10",
  "basep_004-10",
  "basep_005-10",
  "basep_006-10",
  "basep_007-10",
  "basep_008-10",
  "basep_Railing_09-10",
  "basep_Sweep_09-10",
  "Doors_09-10",
  "Glass_09-10",
  "Pillars_09-10",
  "Slabs_09-10",
  "Windows_Frame_09-10",
  "Pillars_11-1",
]
