import { useGLTF } from "@lib/useGLTF"
import { useTexture } from "@react-three/drei"
import { useStore } from "@state/store"

import { DoubleSide, Mesh, RepeatWrapping } from "three"

const index = [
  "env_buildings",
  "roads",
  "buildings_1",
  "ground_plane",
  "buildings_2",
]

const ContextBuildings = ({ env }) => {
  let trees = null;
  let treeMap = null;
  const isIpad = useStore((s) => s.isIpad)
  // Outside of Tauri, we need to load the trees and treeMap
  if (!isIpad) {
    trees = useGLTF(`/models/context/trees.glb`)
    treeMap = useTexture(`/textures/bake/trees.webp`)
    treeMap.flipY = false
  }
  const roofdetails = useTexture(`/textures/bake/roof.webp`)
  roofdetails.flipY = false
  const roofmesh = useGLTF(`/models/context/roof_details.glb`)

  return (
    <group>
      { trees && treeMap && 
        <mesh geometry={(trees.nodes.mesh_0 as Mesh).geometry}>
          <meshBasicMaterial map={treeMap} side={DoubleSide} />
        </mesh>
      }
      <mesh geometry={(roofmesh.nodes.mesh_0 as Mesh).geometry}>
        <meshBasicMaterial map={roofdetails} side={DoubleSide} />
      </mesh>
      <River env={env} />
      {index.map((name) => (
        <ContexElement key={name} name={name} />
      ))}
    </group>
  )
}

const ContexElement = ({ name }) => {
  const { nodes } = useGLTF(`/models/context/${name}.glb`)
  const map = useTexture(`/textures/bake/${name}.webp`)

  map.flipY = false

  return (
    <mesh
      position-y={name === "ground_plane" ? -1 : 0}
      geometry={(nodes.mesh_0 as Mesh).geometry}
    >
      <meshBasicMaterial map={map} side={DoubleSide} color={"#f8f3ec"} />
    </mesh>
  )
}

const River = ({ env }) => {
  const waterTex = useTexture("/textures/watervideo_00000.jpg")
  waterTex.wrapS = waterTex.wrapT = RepeatWrapping
  waterTex.repeat.set(10, 10)

  const { nodes } = useGLTF(`/models/context/river.glb`)
  return (
    <mesh position-y={1} renderOrder={-10} geometry={(nodes.mesh_0 as Mesh).geometry}>
      <meshPhysicalMaterial
        envMap={env}
        bumpMap={waterTex}
        bumpScale={10}
        reflectivity={1}
      />
    </mesh>
  )
}

export default ContextBuildings
