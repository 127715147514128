import { DefaultLoadingManager } from "three"

const HandleLoadingProgress = ({
  totalItems,
  logassets,
  onProgress,
  onloaded,
}) => {
  DefaultLoadingManager.onError = function (url) {
    console.log("There was an error loading " + url)
  }

  DefaultLoadingManager.onProgress = function (url, itemsLoaded, totalAssets) {
    const progress = (itemsLoaded / totalItems) * 100
    logassets && console.log(itemsLoaded)
    // logassets && console.log("object", itemsLoaded, totalItems, totalAssets, url, `${progress}%`)
    if (progress < 99) {
      onProgress(progress)
    } else {
      onloaded()
    }
  }

  return null
}

export default HandleLoadingProgress
