import { Outlet, Link } from "react-router-dom"
import styled from "styled-components"
import Hummingbird from "../assets/images/hummingbird.jpg"

const AuthLayout = () => {
  return (
    <AuthLayoutWrapper>
      <Outlet />
    </AuthLayoutWrapper>
  )
}

export { AuthLayout }

const AuthLayoutWrapper = styled.div`
  height: 100%;
  background-image: url(${Hummingbird});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  // Adding an overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(25, 25, 25, 0.5);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
`
