import { toAbsoluteUrl } from "@utils/toAbsoluteUrl"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

function Logo() {
  const navigate = useNavigate()
  const onegrandLogo = toAbsoluteUrl("onegrand-logo.svg")
  // const logoutIcon = toAbsoluteUrl("icons/logout.svg")

  // const handleLogout = () => {
  //   navigate("/logout")
  // }

  return (
    <Wrapper>
      <div className="onegrand-logo">
        <img onClick={() => navigate("/")} src={onegrandLogo} alt="mark-logo" />
      </div>
    </Wrapper>
  )
}

export default Logo

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 20px;

  .onegrand-logo {
    text-align: center;
    color: rgba(255, 255, 256, 100%);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .logout {
    position: absolute;
    top: 2em;
    right: 2em;
    height: 2em;
    width: 2em;
    cursor: pointer;
    z-index: 100;
  }
`
