export const subMenuLinks: any = [
  {
    onegrand: [
      {
        to: "/onegrand/building",
        label: "The Building",
        children: [],
      },
      {
        to: "/onegrand/green",
        label: "Sustainability",
        children: [],
      },
      {
        to: "/onegrand/amenities",
        label: "Amenities",
        children: [],
      },
      {
        to: "/onegrand/public",
        label: "Neighborhood",
        children: [],
      },
    ],
    workspaces: [
      {
        to: "/workspaces",
        label: "Tower",
        sublabel: "Floors 21-26",
        children: [],
      },
      {
        to: "/workspaces",
        label: "Midrise",
        sublabel: "Floors 11-20",
        children: [],
      },
      {
        to: "/workspaces",
        label: "Base",
        sublabel: "Floors 7-10",
        children: [],
      },
      {
        to: "/workspaces",
        label: "Retail",
        sublabel: "Ground Floor",
        children: [],
      },
    ],
    neighborhood: [
      {
        to: "/neighborhood/restaurants",
        label: "restaurants",
        children: [
          {
            to: "/neighborhood/restaurants/american_cut_tribeca",
            label: "American Cut Tribeca",
          },
          {
            to: "/neighborhood/restaurants/anejo_tribeca",
            label: "Anejo Tribeca",
          },
          {
            to: "/neighborhood/restaurants/anotheroom",
            label: "Anotheroom",
          },
          {
            to: "/neighborhood/restaurants/blue_ribbon_brasserie",
            label: "Blue Ribbon Brasserie",
          },
          {
            to: "/neighborhood/restaurants/bubbys",
            label: "Bubby's",
          },
          {
            to: "/neighborhood/restaurants/brandy_library",
            label: "Brandy Library",
          },
          {
            to: "/neighborhood/restaurants/casa_carmen",
            label: "Casa Carmen",
          },
          {
            to: "/neighborhood/restaurants/charlie_bird",
            label: "Charlie Bird ",
          },
          {
            to: "/neighborhood/restaurants/cipriani",
            label: "Cipriani",
          },
          {
            to: "/neighborhood/restaurants/dominique_ansel_bakery",
            label: "Dominique Ansel Bakery",
          },
          {
            to: "/neighborhood/restaurants/felix",
            label: "Felix",
          },
          {
            to: "/neighborhood/restaurants/frenchette",
            label: "Frenchette",
          },
          {
            to: "/neighborhood/restaurants/gotan",
            label: "Gotan",
          },
          {
            to: "/neighborhood/restaurants/il_corallo_trattoria",
            label: "Il Corallo Trattoria",
          },
          {
            to: "/neighborhood/restaurants/laduree",
            label: "Laduree",
          },
          {
            to: "/neighborhood/restaurants/lindens",
            label: "Linden's ",
          },
          {
            to: "/neighborhood/restaurants/little_prince",
            label: "Little Prince",
          },
          {
            to: "/neighborhood/restaurants/locanda_verde",
            label: "Locanda Verde",
          },
          {
            to: "/neighborhood/restaurants/lola_taverna",
            label: "Lola Taverna",
          },
          {
            to: "/neighborhood/restaurants/lucia_pizza",
            label: "Lucia Pizza of Soho",
          },
          { to: "/neighborhood/restaurants/mariebelle", label: "Mariebelle" },
          { to: "/neighborhood/restaurants/nona_beppa", label: "Nona Beppa" },
          {
            to: "/neighborhood/restaurants/pauls_casablanca",
            label: "Pauls Casablanca",
          },
          {
            to: "/neighborhood/restaurants/paros_tribeca",
            label: "Paros Tribeca",
          },
          { to: "/neighborhood/restaurants/port_said", label: "Port Sa'id" },
          { to: "/neighborhood/restaurants/raouls", label: "Raoul's" },
          {
            to: "/neighborhood/restaurants/scalini_fedeli",
            label: "Scalini Fedeli",
          },
          { to: "/neighborhood/restaurants/shuka", label: "Shuka" },
          { to: "/neighborhood/restaurants/sugarfish", label: "Sugarfish" },
          { to: "/neighborhood/restaurants/sushi_azabu", label: "Sushi Azabu" },
          {
            to: "/neighborhood/restaurants/sushi_gari",
            label: "Sushi of Gari",
          },
          { to: "/neighborhood/restaurants/tamarind", label: "Tamarind" },
          { to: "/neighborhood/restaurants/terra", label: "Terra" },
          {
            to: "/neighborhood/restaurants/the_dominick",
            label: "The Dominick",
          },
          { to: "/neighborhood/restaurants/the_dutch", label: "The Dutch" },
          {
            to: "/neighborhood/restaurants/the_hideaway",
            label: "The Hideaway Tribeca",
          },
          { to: "/neighborhood/restaurants/the_odeon", label: "The Odeon" },
          {
            to: "/neighborhood/restaurants/tinys_bar",
            label: "Tiny's & The Bar Upstairs",
          },
          { to: "/neighborhood/restaurants/two_hands", label: "Two Hands" },
          { to: "/neighborhood/restaurants/walkers", label: "Walker's" },
        ],
      },
      {
        to: "/neighborhood/fitness",
        label: "fitness",
        children: [
          { to: "/neighborhood/fitness/aqua_studio", label: "Aqua Studio" },
          {
            to: "/neighborhood/fitness/barrys_tribeca",
            label: "Barry's Tribeca",
          },
          {
            to: "/neighborhood/fitness/club_300",
            label: "Club 300 at Theradynamics",
          },
          {
            to: "/neighborhood/fitness/corepower_yoga",
            label: "CorePower Yoga",
          },
          { to: "/neighborhood/fitness/dance_body", label: "DanceBody" },
          { to: "/neighborhood/fitness/dog_pound", label: "Dog Pound NYC" },
          { to: "/neighborhood/fitness/equinox_soho", label: "Equinox Soho" },
          {
            to: "/neighborhood/fitness/hudson_boxing",
            label: "Hudson Boxing Gym",
          },
          { to: "/neighborhood/fitness/j_train", label: "J Train" },
          { to: "/neighborhood/fitness/nylo_fitness", label: "NYLO Fitness" },
          { to: "/neighborhood/fitness/oishi_judo", label: "Oishi Judo Club" },
          { to: "/neighborhood/fitness/oscar_smith", label: "Oscar Smith" },
          { to: "/neighborhood/fitness/physique_57", label: "Physique 57" },
          {
            to: "/neighborhood/fitness/planet_fitness",
            label: "Planet Fitness",
          },
          { to: "/neighborhood/fitness/slt_tribeca", label: "SLT Tribeca" },
          { to: "/neighborhood/fitness/the_lodge", label: "The Lodge" },
        ],
      },
      {
        to: "/neighborhood/retail",
        label: "retail",
        children: [
          { to: "/neighborhood/retail/180_store", label: "180 The Store" },
          { to: "/neighborhood/retail/acne_studios", label: "Acne Studios" },
          {
            to: "/neighborhood/retail/alexander_wang",
            label: "Alexander Wang",
          },
          { to: "/neighborhood/retail/burberry", label: "Burberry" },
          { to: "/neighborhood/retail/celine", label: "Celine" },
          { to: "/neighborhood/retail/chanel", label: "Chanel" },
          { to: "/neighborhood/retail/hudson_wine", label: "Hudson Wine" },
          { to: "/neighborhood/retail/norah_walker", label: "Norah Walker" },
          { to: "/neighborhood/retail/rag_bone", label: "Rag & Bone" },
          { to: "/neighborhood/retail/rapha_ny", label: "Rapha NY" },
          { to: "/neighborhood/retail/shinola_store", label: "Shinola Store" },
          { to: "/neighborhood/retail/suitsupply", label: "Suitsupply" },
          { to: "/neighborhood/retail/the_armoury", label: "The Armoury" },
          { to: "/neighborhood/retail/thom_browne", label: "Thom Browne" },
          { to: "/neighborhood/retail/tiffany", label: "Tiffany" },
          { to: "/neighborhood/retail/trader_joes", label: "Trader Joe's" },
        ],
      },
      {
        to: "/neighborhood/hotels",
        label: "hotels",
        children: [
          {
            to: "/neighborhood/hotels/crosby_street",
            label: "Crosby Street Hotel",
          },
          {
            to: "/neighborhood/hotels/greenwich_hotel",
            label: "Greenwich Hotel",
          },
          {
            to: "/neighborhood/hotels/hotel_fouquet",
            label: "Hotel Fouquet New York",
          },
          { to: "/neighborhood/hotels/hotel_hugo", label: "Hotel Hugo" },
          { to: "/neighborhood/hotels/modernhaus", label: "ModernHaus Hugo" },
          { to: "/neighborhood/hotels/nomo_soho", label: "Nomo Soho" },
          { to: "/neighborhood/hotels/roxy_hotel", label: "Roxy Hotel" },
          { to: "/neighborhood/hotels/sixty_soho", label: "SIXTY Soho" },
          { to: "/neighborhood/hotels/soho_54", label: "Soho 54" },
          { to: "/neighborhood/hotels/soho_grand", label: "Soho Grand" },
          {
            to: "/neighborhood/hotels/broome_hotel",
            label: "The Broome Hotel New York",
          },
          { to: "/neighborhood/hotels/dominick", label: "The Dominick" },
          {
            to: "/neighborhood/hotels/wlaker_hotel",
            label: "Walker Hotel Tribeca",
          },
        ],
      },
    ],
    gallery: [
      {
        to: "/gallery/grid",
        label: "Grid",
        children: [],
      },
      {
        to: "/gallery/single",
        label: "Swipe",
        children: [],
      },
    ],
    unitview: [
      {
        label: "Plan",
      },
      {
        label: "Panorama",
      },
      {
        label: "Gallery",
      },
    ],
  },
]
