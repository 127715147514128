import React from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useStore } from "@state/store"
import { useMemo, useState } from "react"
import { floorData } from "@data/floorData"

import CountUp from "react-countup"

const fields = [
  {
    id: "rsf",
    title: "RSF",
  },
  {
    id: "loggia",
    title: "LOGGIA SF",
  },
  {
    id: "terrace",
    title: "TERRACE SF",
  },
  // {
  //   id: "maxOcc",
  //   title: "MAX OCCUPANCY",
  // },
  {
    id: "ceil",
    title: "CEILING HEIGHT",
  },
]

function FloorInfoTable() {
  const selectedFloors = useStore((s) => s.selectedFloors)

  const currentSubMenuIndex = useStore((s) => s.currentSubMenuIndex)

  const location = useLocation()
  const route = location.pathname.split("/")[1]

  const resetCounts = {
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  }

  const [counts, setCounts] = useState({
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  })

  const defaultValues = useMemo(() => {
    return floorData.reduce((acc, e) => {
      acc.rsf += e.rsf
      acc.loggia += e.loggia
      acc.terrace += e.terrace
      acc.maxOcc += e.maxOcc
      return acc
    }, resetCounts)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setCounts(
        floorData.reduce((acc, e) => {
          if (!selectedFloors.includes(e.floor)) return acc

          acc.rsf += e.rsf
          acc.loggia += e.loggia
          acc.terrace += e.terrace
          acc.maxOcc += e.maxOcc
          return acc
        }, resetCounts)
      )
    }, 200)
  }, [selectedFloors])

  return (
    <>
      {route === "workspaces" && currentSubMenuIndex !== -1 && (
        <Wrapper className="bottom-wrapper">
          <div className="bottom-content">
            {fields.map((e, index) => (
              <div key={index} className="floor-count">
                <div>{e.title}</div>
                <div className="count-info">
                  <FormatNumber
                    id={e.id}
                    counts={
                      selectedFloors.length !== 0 ? counts : defaultValues
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
      )}
    </>
  )
}

const FormatNumber = ({ counts, id }) => {
  const [prevCount, setPrevCount] = useState(0)

  React.useEffect(() => {
    setTimeout(() => {
      setPrevCount(counts[id])
    }, 200)
  }, [counts])

  switch (id) {
    case "ceil":
      return <div>14’</div>
    default:
      return (
        <CountUp
          start={prevCount}
          end={counts[id]}
          duration={0.2}
          separator=","
        />
      )
  }
}

export default FloorInfoTable

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.5em 1.5em;

  &.bottom-wrapper {
    bottom: 0;

    & .bottom-content {
      padding-top: 1em;
      border-top: 1px solid #d7c0b0;
    }
  }

  .mark-logo-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  button {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 100;
  }

  .onegrand-logo {
    top: 2em;
    left: 2em;
  }

  .floor-count {
    font-size: 0.9em;
    letter-spacing: 0.1em;
    color: #d7c0b0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;

    .count-info {
      color: #fff;
      font-size: 1.5em;
      font-weight: 600;
    }

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
`
