import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { AnimatedSubMenu, subMenuLinks } from "../../gsap-animations/menu"
import { useStore } from "@state/store"
import { useMemo, useState } from "react"
import OneGrandSubMenu from "./components/OneGrandSubMenu"
import { WorkspacesSubMenu } from "./components/WorkspacesSubMenu"
import { UnitViewSubMenu } from "./components/UnitViewSubMenu"
import { GallerySubMenu } from "./components/GallerySubMenu"
import NeighborhoodSubMenu from "./components/NeighborhoodSubMenu"

export function SubMenuNew() {
  const location = useLocation()
  const route = location.pathname.split("/")[1]
  const unitview = useStore((s) => s.unitview)
  const animationComplete = useStore((s) => s.animationComplete)
  const setFilteredLayer = useStore((s) => s.setFilteredLayer)
  const [activeSubMenu, setActiveSubMenu] = useState(subMenuLinks[0].onegrand)

  useMemo(() => {
    if (unitview) {
      setActiveSubMenu(subMenuLinks[0]["unitview"])
    } else {
      setFilteredLayer([])
      setActiveSubMenu(subMenuLinks[0][route])
    }
  }, [unitview, route])

  return (
    <MainMenuWrapper
      complete={animationComplete}
      galleryRoute={route === "gallery" ? true : false}
    >
      <AnimatedSubMenu className="sub-menu" trigger={activeSubMenu}>
        {route === "onegrand" && (
          <OneGrandSubMenu activeSubMenu={activeSubMenu} />
        )}
        {!unitview && route === "workspaces" && (
          <WorkspacesSubMenu activeSubMenu={activeSubMenu} />
        )}
        {unitview && <UnitViewSubMenu activeSubMenu={activeSubMenu} />}
        {route === "neighborhood" && (
          <NeighborhoodSubMenu activeSubMenu={activeSubMenu} />
        )}
        {route === "gallery" && (
          <GallerySubMenu activeSubMenu={activeSubMenu} />
        )}
      </AnimatedSubMenu>
      <div className="submenu-hide"></div>
    </MainMenuWrapper>
  )
}

const MainMenuWrapper = styled.div<{
  complete: boolean
  galleryRoute: boolean
}>`
  .sub-menu {
    height: calc(100vh - 260px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0 2em;

    a:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .submenu-hide {
    pointer-events: none;
    height: 100px;
    width: 343px;
    position: absolute;
    bottom: 0px;
    /* background: linear-gradient(to bottom, transparent, #393d3d); */
  }

  a {
    opacity: 0;
    color: rgba(215, 192, 176, 40%);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 0.05em;
    transition: all 0.5s ease;

    &.submenu-item {
      color: rgba(215, 192, 176, 40%);
      font-size: 1.5em;
    }

    &.submenu-item.selected {
      opacity: 1;
      color: #d7c0b0;
    }

    &.submenu-item-unitview {
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.7em;
      color: #d7c0b0;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: 400;
      letter-spacing: 0.1em;
    }

    &.submenu-item-unitview.selected > .circle-icon {
      background: #d7c0b0;
      transition: all 0.5s ease;
    }

    & .sublabel {
      font-size: 0.7em;
      font-weight: 400;
    }

    .circle-icon {
      width: 7vh;
      height: 7vh;
      border-radius: 50%;
      border: solid 1px #d7c0b0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s ease;
    }
  }

  .active {
    color: rgba(215, 192, 176, 1);
  }

  .neighborhood-submenu {
    margin-bottom: 0px !important;
  }

  .gallery-navbar {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .gallery-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .gallery-grid-icon {
    width: 80px; /* Set the width and height to the desired size of the circle */
    height: 80px;
    border-radius: 50%; /* Make the div circular */
    border: solid 1px #d7c0b0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .gallery-grid-icon.selected {
    background-color: #d7c0b0;
  }

  .gallery-content {
    font-size: 20px;
    font-weight: 400;
    font-family: "Bicyclette-Regular";
    color: #d7c0b0;
  }

  .gallery-single {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .gallery-single-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: solid 1px #d7c0b0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .gallery-single-icon.selected {
    background-color: #d7c0b0;
  }

  .gallery-single-icon-rectangle {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: solid 1px #d7c0b0;
  }

  .gallery-single-icon-rectangle.selected {
    border: solid 1px #000000;
  }
`
