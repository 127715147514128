import ExtendedMaterial from "@canvas-components/extended-material"
import { useGLTF } from "@lib/useGLTF"
import React from "react"
import { omit } from "lodash-es"
import { DoubleSide } from "three"

const metal = {
  type: "meshPhysicalMaterial",
  roughness: 0.54,
  metalness: 1.5,
  envMapIntensity: 3.8,
  bumpScale: 0.0002,
  reflectivity: 1,
  color: "#69696f",
  side: DoubleSide,
  flatShading: true,
}

const doors = {
  type: "meshPhysicalMaterial",
  roughness: 0.54,
  metalness: 1.5,
  envMapIntensity: 3.8,
  bumpScale: 0.0002,
  reflectivity: 1,
  color: "#656569",
  side: DoubleSide,
  flatShading: true,
}

const base = {
  type: "meshPhysicalMaterial",
  roughness: 0.54,
  metalness: 1.2,
  envMapIntensity: 3.8,
  bumpScale: 0.0002,
  reflectivity: 1,
  color: "#313242",
  side: DoubleSide,
  flatShading: true,
}

const glass = {
  type: "meshPhysicalMaterial",
  opacity: 0.9,
  ior: 2,
  transparent: true,
  envMapIntensity: 1.5,
  roughness: 0.09,
  reflectivity: 1.5,
  color: "#2b3845",
}

const materialIndex = {
  Glass: glass,
  Windows: metal,
  Slabs: metal,
  Pillars: metal,
  Doors: glass,
  Facade: metal,
  Garage: metal,
  Pilars: metal,
  Solid: metal,
  Window: metal,
  Balcony: metal,

  Metal: metal,
  Door: glass,
  Shutters: metal,
  Fascade: metal,
  Green: {
    type: "meshBasicMaterial",
    color: "#353333",
  },
  Roof: metal,
  basep: base,
  basemetal: base,
  Tower: metal,
}

const MeshElement = ({ name, hovered, highlighted, env }) => {
  const { nodes } = useGLTF(`/models/floors/main/${name}.glb`)

  const [compiled, setCompiled] = React.useState(false)

  const array = Object.values(nodes) as THREE.Mesh[]

  const shaderRefsArray = React.useRef<React.RefObject<any>[]>(
    array.map(() => React.createRef<any>())
  )

  React.useEffect(() => {
    if (!compiled) return
    shaderRefsArray.current.forEach((ref, i) => {
      if (name.includes("Glass")) {
        ref.current.update("uActive", hovered ? 1 : 0)
        // ref.current.update("uSelected", selected ? 1 : 0)
        ref.current.update("uHighlighted", highlighted ? 1 : 0)
      }
    })
  }, [compiled, hovered, highlighted])

  return (
    <group>
      {array.map((mesh, index) => (
        <mesh
          onClick={(e) => {
            e.stopPropagation()
            console.log(name)
          }}
          name={index + "_floor"}
          key={index}
          geometry={mesh.geometry}
        >
          <ExtendedMaterial
            mesh={mesh}
            {...omit(materialIndex[name.split("_")[0]], "type")}
            //@ts-ignore
            envMap={env}
            ref={shaderRefsArray.current[index]}
            setCompiled={() => setCompiled(true)}
            name={mesh.name}
            flatShading={true}
            materialType={materialIndex[name.split("_")[0]].type}
            uniforms={[
              {
                id: "uActive",
                value: 0,
                type: "float",
              },
              {
                id: "uSelected",
                value: 0,
                type: "float",
              },
              {
                id: "uHighlighted",
                value: 0,
                type: "float",
              },
            ]}
          />
        </mesh>
      ))}
    </group>
  )
}

export default MeshElement
