import { useStore } from "@state/store"
import styled from "styled-components"
import SVG from "react-inlinesvg"
import { NavLink } from "react-router-dom"

type GallerySubMenuProps = {
  activeSubMenu: any
}

export const GallerySubMenu: React.FC<GallerySubMenuProps> = ({
  activeSubMenu,
}) => {
  const currentSubMenuIndex = useStore((s) => s.currentSubMenuIndex)
  const setCurrentSubMenu = useStore((s) => s.setCurrentSubMenu)

  const resetSubMenuIndex = (index: number) => {
    setCurrentSubMenu(index)
  }

  return (
    <>
      {activeSubMenu.map(({ label }: any, index: number) => {
        return (
          <NavLink
            key={label}
            to="#"
            className={`submenu-item-unitview ${
              currentSubMenuIndex === index ? "selected" : ""
            }`}
            onClick={() => {
              resetSubMenuIndex(index)
            }}
          >
            <div className="circle-icon">
              <StyledSvg
                data-isactive={currentSubMenuIndex === index}
                src={`/icons/${label.toLowerCase()}.svg`}
                title="Icon"
              />
            </div>
            {label}
          </NavLink>
        )
      })}
    </>
  )
}

//@ts-ignore
const StyledSvg = styled(SVG)`
  & path,
  rect {
    stroke: #393d3d;
    transition: all 0.5s ease;
  }

  &[data-isactive="false"] {
    & path,
    rect {
      stroke: #d7c0b0;
      fill: #393d3d;
      transition: all 0.5s ease;
    }
  }
`
