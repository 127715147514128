import { shaderMaterial, useTexture } from "@react-three/drei"
import React from "react"
import { Texture } from "three"
//@ts-ignore
import vertex from "./vertex.glsl"
//@ts-ignore
import fragment from "./fragment.glsl"
import { extend } from "@react-three/fiber"
import { useGLTF } from "@lib/useGLTF"

const name = ["plant_01", "plant_02", "plant_03"]

const TreeShader = shaderMaterial(
  {
    uDiffuse: new Texture(),
    uAlpha: new Texture(),
  },
  vertex,
  fragment
)
type Props = {}

extend({ TreeShader })

function AlphaTrees({}: Props) {
  return (
    <group>
      {name.map((name, index) => (
        <Tree key={index} name={name} />
      ))}
    </group>
  )
}

function Tree({ name }) {
  const { nodes } = useGLTF(`/models/plants/${name}.glb`)
  const alpha = useTexture(`/models/plants/${name}.jpg`)
  alpha.flipY = false
  return (
    <mesh renderOrder={1} geometry={nodes["mesh_0"].geometry}>
      {/* @ts-ignore */}
      <treeShader uDiffuse={alpha} uAlpha={alpha} transparent={true} />
    </mesh>
  )
}

export default AlphaTrees
