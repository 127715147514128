import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { useStore } from "@state/store"

interface AnimatedMainMenuProps {
  children: React.ReactNode
  className?: string
}

export const AnimatedMainMenu: React.FC<AnimatedMainMenuProps> = ({
  children,
  className,
}) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const setAnimationComplete = useStore((state) => state.setAnimationComplete)

  useEffect(() => {
    if (parentRef.current) {
      gsap.fromTo(
        parentRef.current.children,
        { opacity: 0, y: 0 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.2,
          ease: "power1.inOut",
          onComplete: () => {
            setAnimationComplete(true)
          },
        }
      )
    }
  }, [])

  return (
    <div className={className} ref={parentRef}>
      {React.Children.toArray(children)}
    </div>
  )
}
