import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { validateGuestToken, getCustomToken } from "../../auth/core/requests"
import { signInWithCustomToken } from "firebase/auth"
import { auth } from "../../firebase"
import styled from "styled-components"

const TokenHandler = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token")

    if (!token) return navigate("/auth/login")

    const authenticateGuest = async () => {
      setLoading(true)
      try {
        const userId = await validateGuestToken(token)

        if (!userId || userId?.exists === false) {
          //   navigate("/auth/login") // Redirect if token validation fails
          setInvalidCredentials(true)
          setLoading(false)
          return
        }

        const customToken = await getCustomToken(userId)
        await signInWithCustomToken(auth, customToken)
        setInvalidCredentials(false)
        setLoading(false)
        navigate("/onegrand/building") // Navigate on successful authentication
      } catch (error) {
        console.error("Authentication error:", error)
        setLoading(false)
        navigate("/auth/login") // Redirect on error
      }
    }

    authenticateGuest()
  }, [location, navigate])

  return (
    <Wrapper>
      {loading ? (
        <div className="loading">Loading...</div>
      ) : invalidCredentials ? (
        <div className="card">Invalid credentials</div>
      ) : null}
    </Wrapper>
  )
}

export { TokenHandler }

const Wrapper = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d7c0b0;

  .loading {
    font-size: 2.5em;
    color: #d7c0b0;
  }

  .card {
    font-size: 2.5em;
    padding: 5em;
    border-radius: 1.5em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
