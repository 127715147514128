import Logo from "../../Logo"
import styled from "styled-components"
import { SubMenuNew } from "@components/Menu/SubMenuNew"
import FloorInfoTable from "@components/FloorInfoTable"

const Sidebar = () => {
  return (
    <Wrapper>
      <Logo />
      <SubMenuNew />
      <FloorInfoTable />
    </Wrapper>
  )
}

export default Sidebar

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 343px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  z-index: 1000;
  background-color: #393d3d;
  transition: all 0.3s;
  height: calc(100% - 5em);
`
