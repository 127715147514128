import { toAbsoluteUrl } from "@utils/toAbsoluteUrl"
import { FC } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface ErrorProps {
  isPrivate?: boolean
}

const Error404: FC<ErrorProps> = ({ isPrivate }) => {
  return (
    <Error404Wrapper>
      <div style={{ color: "red" }}>We can't find that page.</div>

      <div>
        <img src={toAbsoluteUrl("/media/auth/404-error.png")} alt="" />
        <img src={toAbsoluteUrl("/media/auth/404-error-dark.png")} alt="" />
      </div>

      <div>
        <Link to={isPrivate ? "/onegrand/building" : "/auth/login"}>
          Return Home
        </Link>
      </div>
    </Error404Wrapper>
  )
}

export { Error404 }

const Error404Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
`
