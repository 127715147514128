import { useState } from "react"
import * as Yup from "yup"
import clsx from "clsx"
import { useFormik } from "formik"
import { firebaseLogin } from "../core/requests"
import styled from "styled-components"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
})

const initialValues = {
  email: "onegrand@vmistudio.com",
  password: "",
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const userCredential = await firebaseLogin(
          values.email,
          values.password
        )
      } catch (error) {
        console.error("Error in form submission:", error)
        setStatus("The login details are incorrect or other error occurred")
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <LoginWrapper>
      <div className="title">One Grand</div>
      <form onSubmit={formik.handleSubmit} noValidate>
        {formik.status && <div>{formik.status}</div>}
        <div className="divider"></div>
        <div className="input-field">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <img
            src={showPassword ? "/icons/eye-off.svg" : "/icons/eye.svg"}
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: "absolute",
              height: "20px",
              width: "20px",
              right: "10px",
            }}
          ></img>
          {formik.touched.password && formik.errors.password && (
            <span role="alert" className="alert">
              {formik.errors.password}
            </span>
          )}
        </div>
        <div className="divider"></div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <div className="divider-double"></div>
      </form>
    </LoginWrapper>
  )
}

const LoginWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input,
    button {
      font-family: "Bicyclette-Regular";
      background: rgba(215, 192, 176, 0.77);
      color: white;
      width: 25em;
      border: none;
      padding: 10px;
    }

    input::placeholder {
      color: white;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.1rem;
    }

    button {
      background: rgba(57, 61, 61, 0.77);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.1rem;
    }

    .input-field {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .alert {
      color: #ff293e;
      font-size: 0.8rem;
      font-weight: 700;
      margin-top: 0.3rem;
      letter-spacing: 0.05rem;
      align-self: flex-start;
    }

    .divider {
      border-top: 1px solid rgba(215, 192, 176, 0.77);
      width: 100%;
      margin: 1rem 0;
    }

    .divider-double {
      border-top: 1px solid rgba(215, 192, 176, 0.77);
      border-bottom: 2px solid rgba(215, 192, 176, 0.77);
      height: 8px;
      width: 100%;
      margin: 1rem 0;
    }
  }

  .title {
    font-size: 6rem;
    margin-bottom: 3rem;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
`
