import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./core/Auth"

export function Logout() {
  const { logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await logout()
        navigate("/auth/login")
        console.log("Logged out successfully")
      } catch (error) {
        console.error("Failed to log out:", error)
      }
    }

    handleLogout()
  }, [logout, navigate])

  return <div>Logging out...</div>
}
