import { Navigate, Route, Routes } from "react-router-dom"
import { Login } from "./components/Login"
import { AuthLayout } from "../layouts/AuthLayout"
import { Error404 } from "@pages/errors"

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route index element={<Navigate to="/auth/login" />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<Error404 isPrivate={false} />} />
    </Route>
  </Routes>
)

export { AuthPage }
