import { useStore } from "@state/store"
import React from "react"
import { NavLink } from "react-router-dom"

type WorkspacesSubMenuProps = {
  activeSubMenu: any
}

export const WorkspacesSubMenu: React.FC<WorkspacesSubMenuProps> = ({
  activeSubMenu,
}) => {
  const setSelectedFloors = useStore((s) => s.setSelectedFloors)
  const selectedFloors = useStore((s) => s.selectedFloors)

  return (
    <>
      {activeSubMenu.map(({ label, sublabel }: any) => (
        <MenuElement
          key={label}
          selectedFloors={selectedFloors}
          setSelectedFloors={setSelectedFloors}
          label={label}
          sublabel={sublabel}
        />
      ))}
    </>
  )
}

const MenuElement = ({
  label,
  sublabel,
  selectedFloors,
  setSelectedFloors,
}) => {
  const total = React.useMemo(() => {
    return selectedFloors.reduce((acc: number, floor: number) => {
      return acc + floor
    }, 0)
  }, [selectedFloors])

  const thisFloorCount = React.useMemo(() => {
    if (!floorsIndex[label?.toLowerCase()]) return 0
    return floorsIndex[label.toLowerCase()].reduce(
      (acc: number, floor: number) => {
        return acc + floor
      },
      0
    )
  }, [label])

  const active = thisFloorCount === total

  return (
    <NavLink
      to="#"
      key={label}
      className={`submenu-item ${active ? "selected" : ""}`}
      onClick={() => {
        setSelectedFloors(active ? [] : floorsIndex[label.toLowerCase()])
      }}
    >
      {label}
      <div className="sublabel">{sublabel}</div>
    </NavLink>
  )
}

const floorsIndex = {
  tower: [21, 22, 23, 24, 25, 26],
  midrise: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  base: [7, 8, 9, 10],
  retail: [1],
}
