import { Outlet } from "react-router-dom"
import { AuthProvider } from "./auth/core/Auth"

function App() {
  // user
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  )
}

export default App
