import axios from "axios"
import { AuthModel, UserModel } from "./models"
import { auth } from "../../firebase"
import { signInWithEmailAndPassword } from "firebase/auth"

const API_URL = import.meta.env.VITE_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`
export const VALIDATE_TOKEN_AND_CREATE_GUEST = `${API_URL}/auth/validate-guest-token`
export const GET_CUSTOM_TOKEN = `${API_URL}/auth/get-custom-token`

export async function firebaseLogin(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
    console.log(userCredential?.user)

    return userCredential?.user
    // setCurrentUser(userCredential.user);
    // You can return the user or perform other actions as needed
  } catch (error) {
    console.error("Login error:", error)
    // Handle login errors here
  }
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    accessToken: token,
  })
}

export async function refreshAuthToken(auth: AuthModel): Promise<AuthModel> {
  console.log("refreshAuthToken", auth)
  const response = await axios.post(REFRESH_TOKEN_URL, {
    userId: auth.uid,
    refresh_token: auth.refresh_token,
  })

  // new AuthModel
  return {
    ...auth,
    accessToken: response.data.accessToken,
    refresh_token: response.data.refresh_token,
  }
}

export async function validateGuestToken(token: string) {
  const response = await axios.post(VALIDATE_TOKEN_AND_CREATE_GUEST, {
    token: token,
  })

  return response.data
}

export async function getCustomToken(userId: string) {
  const response = await axios.post(GET_CUSTOM_TOKEN, {
    userId,
  })

  console.log(response)
  return response.data
}
