import AlphaTrees from "@canvas-components/alpha-trees/alpha-trees"
import BuidlingFloors from "@canvas-components/building-floors/building-floors"
import ContextBuildings from "@canvas-components/context-buildings"
import { useCubeTexture } from "@react-three/drei"

const World = () => {
  const env = useCubeTexture(
    ["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"],
    { path: "/textures/cubes/newsky/" }
  )

  return (
    <>
      <AlphaTrees />
      <ContextBuildings env={env} />
      <BuidlingFloors env={env} />
      {/* <axesHelper args={[100]} /> */}
    </>
  )
}

export default World
