import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { AnimatedMainMenu } from "../../gsap-animations/menu"
import { useStore } from "@state/store"

const navLinks = [
  {
    to: "/onegrand",
    label: "One Grand",
  },
  {
    to: "/workspaces",
    label: "Workspaces",
  },
  {
    to: "/neighborhood",
    label: "Neighborhood",
  },
  {
    to: "/gallery",
    label: "Gallery",
  },
]

export function MainMenu() {
  const animationComplete = useStore((s) => s.animationComplete)
  const setOrbitScroll = useStore((s) => s.setOrbitScroll)
  const setCameraMove = useStore((s) => s.setCameraMove)
  const setHoverClick = useStore((s) => s.setHoverClick)
  const setCurrentMainMenu = useStore((s) => s.setCurrentMainMenu)
  const unitview = useStore((s) => s.unitview)

  return (
    <MainMenuWrapper complete={animationComplete}>
      <AnimatedMainMenu className="main-menu">
        {navLinks.map(({ to, label }, index) => (
          <NavLink
            style={{ opacity: unitview ? 0 : 1 }}
            key={to}
            to={to}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            onClick={() => {
              switch (index) {
                case 0:
                  setOrbitScroll(true)
                  setHoverClick(false)
                  setCurrentMainMenu(0)

                  break
                case 1:
                  setOrbitScroll(true)
                  setHoverClick(true)
                  setCurrentMainMenu(1)
                  setCameraMove(true)
                  break
                case 2:
                  setHoverClick(false)
                  setCurrentMainMenu(2)
                  break
                case 3:
                  setHoverClick(false)
                  setCurrentMainMenu(3)
                  break
                default:
                  break
              }
            }}
          >
            {label}
          </NavLink>
        ))}
      </AnimatedMainMenu>
    </MainMenuWrapper>
  )
}

const MainMenuWrapper = styled.div<{ complete: boolean }>`
  background-color: #393d3d;
  position: absolute;
  transition: opacity 0.2s ease;
  z-index: 1;
  height: 5em;
  width: 100%;
  bottom: 0;

  .main-menu {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5em;
    padding: 0 7vw;
  }

  a {
    transition: ${(props) => (props.complete ? "all 0.5s ease" : "none")};
    color: #ffffff60;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  .active {
    color: #d7c0b0;
  }
`
