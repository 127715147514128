import TCanvas from "@canvas-components/TCanvas"
import { Outlet } from "react-router-dom"
import { MainMenu } from "@components/Menu"
import styled from "styled-components"
import Sidebar from "@components/Sidebar/Sidebar"

function DefaultLayout() {
  return (
    <Wrapper>
      <div className="main-layout">
        <Sidebar />
        <div className="view">
          <TCanvas />
          <Outlet />
        </div>
        <MainMenu />
      </div>
    </Wrapper>
  )
}

export default DefaultLayout

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #393d3d;

  .left-ex {
    position: absolute;
    top: 325px;
    left: 394px;
  }

  .right-ex {
    position: absolute;
    top: 525px;
    right: 80px;
  }

  .right-top-ex {
    position: absolute;
    top: 232px;
    right: 80px;
  }

  .view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5em);
  }

  .main-layout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`
