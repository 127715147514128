import React, { useEffect, useRef } from "react"
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import styled from "styled-components"
import { useStore } from "@state/store"

type NeighborhoodSubMenuProps = {
  activeSubMenu: any
}

const NeighborhoodSubMenu: React.FC<NeighborhoodSubMenuProps> = ({
  activeSubMenu,
}) => {
  const prevValue = useRef("")
  const accordionRef = useRef<HTMLDivElement>(null)

  const {
    setMapAccordionValue,
    currAccordionItemIndex,
    setCurrAccordionItemIndex,
  } = useStore((s) => ({
    setMapAccordionValue: s.setMapAccordionValue,
    currAccordionItemIndex: s.currAccordionItemIndex,
    setCurrAccordionItemIndex: s.setCurrAccordionItemIndex,
  }))

  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.style.opacity = "1"
    }
    console.log("NIEGHBOUR")
  }, [])

  function handleItemClick(index: number) {
    setCurrAccordionItemIndex(index)
  }

  return (
    <Wrapper>
      <Accordion.Root
        ref={accordionRef}
        className="AccordionRoot"
        type="single"
        defaultValue=""
        collapsible
        onValueChange={(value) => {
          if (!value || value !== prevValue.current)
            setCurrAccordionItemIndex(-1)
          setMapAccordionValue(value)
          prevValue.current = value
        }}
      >
        {activeSubMenu.map((item: any, index: number) => {
          return (
            <Accordion.Item
              key={index}
              className="AccordionItem"
              value={item.label}
            >
              <AccordionTrigger>{item.label}</AccordionTrigger>
              <AccordionContent>
                {item.children?.map(({ to, label }, index: number) => (
                  <div
                    key={to}
                    onClick={() => handleItemClick(index)}
                    className={
                      currAccordionItemIndex == index
                        ? "accordion-item selected"
                        : "accordion-item"
                    }
                  >
                    <span style={{ marginRight: "5px" }}>
                      {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                    </span>{" "}
                    {label}
                  </div>
                ))}
              </AccordionContent>
            </Accordion.Item>
          )
        })}
      </Accordion.Root>
    </Wrapper>
  )
}

const AccordionTrigger: React.FC<any> = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={`AccordionTrigger` + ` ${className}`}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon
          height={25}
          width={25}
          className="AccordionChevron"
          aria-hidden
        />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent: React.FC<any> = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={`AccordionContent` + ` ${className}`}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)

export default NeighborhoodSubMenu

const Wrapper = styled.div`
  button,
  h3 {
    all: unset;
  }

  .accordion-item {
    color: #ffffff;
    font-size: 1.2em;
    letter-spacing: 0.05em;

    &:not(:first-child) {
      margin-top: 0.8em;
    }
  }

  .accordion-item.selected {
    color: #d7c0b0 !important;
  }

  .AccordionRoot {
    opacity: 0;
    transition: opacity 2s ease;
  }

  .AccordionItem {
    overflow: hidden;
    margin-top: 1px;
    border-top: 1px solid #616464;
    padding: 10px 0;

    &:last-child {
      border-bottom: 1px solid #616464;
    }
  }

  .AccordionItem:focus-within {
    position: relative;
    z-index: 1;
  }

  .AccordionHeader {
    display: flex;
  }

  .AccordionTrigger {
    font-family: inherit;
    background-color: transparent;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 1;
    color: #d7c0b0;
    text-transform: uppercase;
  }

  .AccordionContent {
    overflow: hidden;
    font-size: 15px;
    color: #ffffff;
  }
  .AccordionContent[data-state="open"] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionContent[data-state="closed"] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  .AccordionContentText {
    padding: 15px 20px;
  }

  .AccordionChevron {
    color: #d7c0b0;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionTrigger[data-state="open"] > .AccordionChevron {
    transform: rotate(180deg);
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`
