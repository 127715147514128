import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { useStore } from "@state/store"

interface AnimatedSubMenuProps {
  children: React.ReactNode
  className?: string
  trigger?: any
}

/**
 * AnimatedSubMenu
 * @param children - The sub menu links
 * @param className - The class name of the sub menu needed for styling the children of this component.
 * @param trigger - Used to reset the animation of sub menu. Must be a state that rerender the component.
 */
export const AnimatedSubMenu: React.FC<AnimatedSubMenuProps> = ({
  children,
  className,
  trigger,
}) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const setAnimationComplete = useStore((state) => state.setAnimationComplete)

  useEffect(() => {
    if (parentRef.current) {
      gsap.fromTo(
        parentRef.current.children,
        { opacity: 0, x: 10 },
        {
          opacity: 1,
          x: 0,
          duration: 0.2,
          stagger: 0.1,
          ease: "power1.in",
          onComplete: () => {
            setAnimationComplete(true)
          },
        }
      )
    }
  }, [trigger])

  return (
    <div className={className} ref={parentRef} key={trigger}>
      {React.Children.toArray(children)}
    </div>
  )
}
