import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import App from "../App"
import { useAuth } from "../auth/core/Auth"
import { AuthPage } from "../auth/AuthPage"
import { PrivateRoutes } from "./PrivateRoutes"
import { TokenHandler } from "@pages/guests/TokenHandler"
import { Error404 } from "@pages/errors"
import { ErrorsPage } from "@pages/errors/ErrorsPage"
import { Logout } from "../auth/Logout"
import { useStore } from "@state/store"

const { BASE_URL } = import.meta.env

const AppRoutes = () => {
  const { currentUser } = useAuth()
  const isIpad = useStore((s) => s.isIpad)
  let isAllowed = false;
  if (!navigator.onLine && isIpad) {
    isAllowed = true;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {(currentUser || isAllowed)? (
            <>
              <Route index element={<Navigate to="/onegrand" />} />
              <Route path="/*" element={<PrivateRoutes />} />
              <Route path="token" element={<TokenHandler />} />
            </>
          ) : (
            <>
              <Route index element={<Navigate to="/auth/login" />} />
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="token" element={<TokenHandler />} />
              <Route path="*" element={<Error404 isPrivate={false} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
