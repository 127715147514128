export const floorData = [
  {
    floor: 1,
    rsf: 7730,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },

  {
    floor: 2,
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },
  {
    floor: 3,
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },
  {
    floor: 4,
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },
  {
    floor: 5,
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },
  {
    floor: 6,
    rsf: 0,
    loggia: 0,
    terrace: 0,
    maxOcc: 0,
  },
  {
    floor: 7,
    rsf: 12761 + 13000,
    loggia: 0,
    terrace: 13000,
    maxOcc: 360,
  },
  {
    floor: 8,
    rsf: 25516,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 9,
    rsf: 24256,
    loggia: 0,
    terrace: 900,
    maxOcc: 360,
  },
  {
    floor: 10,
    rsf: 24074,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 11,
    rsf: 20151,
    loggia: 0,
    terrace: 2943,
    maxOcc: 360,
  },
  {
    floor: 12,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 13,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 14,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 15,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 16,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 17,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 18,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 19,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 20,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 21,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 22,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 23,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 24,
    rsf: 21050,
    loggia: 900,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 25,
    rsf: 20151,
    loggia: 0,
    terrace: 0,
    maxOcc: 360,
  },
  {
    floor: 26,
    rsf: 15106,
    loggia: 0,
    terrace: 4505,
    maxOcc: 360,
  },
]
