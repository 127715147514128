import { Canvas, useFrame } from "@react-three/fiber"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { useStore } from "@state/store"
import HandleLoadingProgress from "@utils/loading"
import * as THREE from "three"
import { CameraTracker } from "./CameraTracker"
import { OrbitControls, ScrollControls } from "@react-three/drei"
import { useLocation } from "react-router-dom"
import usePositionSettings from "@utils/usePositionSettings"
import CameraPaths from "@canvas-components/CameraPaths"
import gsap from "gsap"
import World from "@canvas-components/world/world"

const TCanvas = () => {
  const location = useLocation()
  const positions = usePositionSettings()
  const [hotSpotsVisible, setHotSpotsVisible] = useState(false)
  const isCanvasLoaded = useStore((s) => s.isCanvasLoaded)
  // const currentMainMenu = useStore((state) => state.currentMainMenu)
  const initialCameraPos = useMemo(() => new THREE.Vector3(0, 0, 7), [])
  const canvasRef = useRef()
  const setCanvasLoad = useStore((s) => s.setCanvasLoad)
  const circleRef = useRef<SVGCircleElement>(null)
  const circleCircumference = 408

  const targetPosition = useMemo(
    () =>
      new THREE.Vector3(
        positions.cameraPosition.x,
        positions.cameraPosition.y,
        positions.cameraPosition.z
      ),
    [positions.cameraPosition]
  )

  useEffect(() => {
    setHotSpotsVisible(false)
  }, [location.pathname])

  useEffect(() => {
    if (isCanvasLoaded) {
      gsap.to(canvasRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power1.in",
      })
    }
  }, [isCanvasLoaded])

  return (
    <CanvasWrapper>
      <Canvas
        ref={canvasRef}
        frameloop="demand"
        camera={{
          position: new THREE.Vector3(-70, 100, 210),
          fov: 55,
          near: 30,
          far: 5000,
        }}
        dpr={1.5}
      >
        <Suspense fallback={null}>
          <HandleLoadingProgress
            logassets={true}
            totalItems={471}
            onProgress={(progress) => {
              if (!circleRef.current || progress >= 100) return
              const offset =
                circleCircumference - circleCircumference * (progress * 0.01)
              circleRef.current.style.strokeDashoffset = offset.toString()
            }}
            onloaded={() => {
              !isCanvasLoaded &&
                setTimeout(() => {
                  setCanvasLoad(true)
                }, 1000)
            }}
          />
          <color attach="background" args={["#c2dff8"]} />
          <fog attach="fog" args={["#c2dff8", 1, 6000]} />
          <group rotation-y={-0.5} position-y={-1.4} scale={1.3}>
            <World />
            <CameraRig />
          </group>
        </Suspense>
      </Canvas>
      <div
        style={{
          position: "absolute",
          height: "15vh",
          width: "15vh",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          transition: "opacity 0.5s ease-in-out",
          opacity: isCanvasLoaded ? 0 : 1,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          <svg viewBox="0 0 140 140" width="100%" height="100%">
            <circle
              ref={circleRef}
              cx="70"
              cy="70"
              r="65"
              fill="none"
              stroke="#393d3d"
              strokeWidth="4"
              strokeDasharray={circleCircumference}
              strokeDashoffset={circleCircumference}
              style={{
                transform: "rotate(-90deg)",
                transformOrigin: "50% 50%",
              }}
            />
          </svg>
        </div>
        <div
          style={{
            color: "#d7c0b0",
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Loading...</span>
        </div>
      </div>
    </CanvasWrapper>
  )
}

const CameraRig = () => {
  const target = new THREE.Vector3(0, 90, 0)

  useFrame(({ camera }) => {
    camera.lookAt(target)
  })
  return (
    <OrbitControls
      maxDistance={500}
      minDistance={200}
      enableDamping
      rotateSpeed={0.4}
      enableZoom={true}
      enablePan={false}
      minPolarAngle={0.8}
      maxPolarAngle={1}
      // enabled={currentMainMenu === 1}
    />
  )
}

export default TCanvas

const CanvasWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  height: 100%;

  width: calc(100% - 343px);
  left: 343px;

  canvas {
    transition: all 0.5s;
    opacity: 0;
  }
`
