precision mediump float;

varying vec2 vUv;
// varying vec2 vUv1;
uniform sampler2D uDiffuse;
uniform sampler2D uAlpha;

void main() {

  // vec4 color = texture2D(uDiffuse, vUv);
  vec4 alpha = texture2D(uAlpha, vUv);

  float a = alpha.r;

  gl_FragColor = vec4(0.58, 0.66, 0.43, a);

}