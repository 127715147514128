import { lazy, Suspense, useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import DefaultLayout from "../layouts/DefaultLayout"

const OneGrandPage = lazy(() => import("../pages/one-grand/OneGrandPage"))
const WorkspacesPage = lazy(() => import("../pages/workspaces/WorkspacesPage"))
const LocationPage = lazy(() => import("../pages/location"))
const GalleryPage = lazy(() => import("../pages/gallery/Gallery"))

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        {/* Redirect from auth pages to home */}
        <Route path="auth/*" element={<Navigate to="/onegrand/building" />} />
        <Route path="token/*" element={<Navigate to="/onegrand/building" />} />

        <Route
          path="onegrand/*"
          element={
            <SuspensedView>
              <OneGrandPage />
            </SuspensedView>
          }
        ></Route>

        <Route
          path="workspaces/*"
          element={
            <SuspensedView>
              <WorkspacesPage />
            </SuspensedView>
          }
        />
        <Route
          path="neighborhood/*"
          element={
            <SuspensedView>
              <LocationPage />
            </SuspensedView>
          }
        />
        <Route
          path="gallery/*"
          element={
            <SuspensedView>
              <GalleryPage />
            </SuspensedView>
          }
        />

        <Route
          path="*"
          element={<Navigate to="/error/404" replace={false} />}
        />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({ children }) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>
}

const Loader = () => {
  useEffect(() => {
    console.log("LOADER")

    return () => {
      console.log("loaded")
    }
  }, [])

  return null
}

export { PrivateRoutes }
